@mixin dark-mode
{
	--main-text-color: #CCC;
	--main-bg-color: #131313;
	--link-text-color: #337AB7;
	--active-link-text-color: #23527C;
	--heading-link-text-color: #C2A970;

	--input-text-color: #DFE0E4;
	--input-bg-color: #222;
	--input-border-color: #010101;

	--button-primary-text-color: white;
	--button-primary-bg-color: #337AB7;
	--button-primary-border-color: #2E6DA4;
	--button-primary-hover-bg-color: #286090;
	--button-primary-hover-border-color: #204D74;
	--button-primary-active-bg-color: #204D74;
	--button-primary-active-border-color: #122B40;

	--button-secondary-text-color: #CCC;
	--button-secondary-bg-color: #2C2C2C;
	--button-secondary-border-color: #313131;
	--button-secondary-hover-bg-color: #3E3E3E;
	--button-secondary-hover-border-color: #313131;
	--button-secondary-active-bg-color: #3E3E3E;
	--button-secondary-active-border-color: #8C8C8C;

	--button-warning-text-color: #CCC;
	--button-warning-bg-color: #9A2830;
	--button-warning-border-color: #9A2830;
	--button-warning-hover-bg-color: #D42722;
	--button-warning-hover-border-color: #D42722;
	--button-warning-active-bg-color: #E7505A;
	--button-warning-active-border-color: #E7505A;

	--iframe-border-color: #C2A970;
	--table-secondary-row-bg-color: var(--input-bg-color);
	--grouped-chapters-bg-color: var(--button-secondary-bg-color);
	--error-color: var(--button-warning-bg-color);
	--banner-cover-bg-color: var(--button-secondary-hover-bg-color);

	--syntax-highlighting-keyword: #C586C0;
	--syntax-highlighting-bare-word: #9CDCFE;
	--syntax-highlighting-string: #CE9178;
	--syntax-highlighting-symbol: #D4D4D4;
}

:root,
:root.light
{
	--main-text-color: #010101;
	--main-bg-color: white;
	--link-text-color: #337AB7;
	--active-link-text-color: #23527C;
	--heading-link-text-color: #E26A6A;

	--input-text-color: #010101;
	--input-bg-color: #DDD;
	--input-border-color: #CCC;

	--button-primary-text-color: white;
	--button-primary-bg-color: #337AB7;
	--button-primary-border-color: #2E6DA4;
	--button-primary-hover-bg-color: #286090;
	--button-primary-hover-border-color: #204D74;
	--button-primary-active-bg-color: #204D74;
	--button-primary-active-border-color: #122B40;

	--button-secondary-text-color: #333;
	--button-secondary-bg-color: #E6E6E6;
	--button-secondary-border-color: #CCC;
	--button-secondary-hover-bg-color: #D4D4D4;
	--button-secondary-hover-border-color: #ADADAD;
	--button-secondary-active-bg-color: #C2C2C2;
	--button-secondary-active-border-color: #8C8C8C;

	--button-warning-text-color: white;
	--button-warning-bg-color: #E7505A;
	--button-warning-border-color: #E7505A;
	--button-warning-hover-bg-color: #E12330;
	--button-warning-hover-border-color: #DC1E2B;
	--button-warning-active-bg-color: #C51B26;
	--button-warning-active-border-color: #A1161F;

	--button-required-text-color: #D6EBD6;
	--button-required-bg-color: #393;
	--button-required-border-color: #297A29;
	--button-required-icon-bg-color: #297A29;
	--button-required-hover-text-color: #DDF4DD;
	--button-required-hover-bg-color: #53C653;
	--button-required-hover-icon-bg-color: #429E42;
	--button-required-hover-border-color: #429E42;

	--button-forbidden-text-color: #EBD6D6;
	--button-forbidden-bg-color: #933;
	--button-forbidden-border-color: #7A2929;
	--button-forbidden-icon-bg-color: #7A2929;
	--button-forbidden-hover-text-color: #F4DDDD;
	--button-forbidden-hover-bg-color: #C65353;
	--button-forbidden-hover-icon-bg-color: #9E4242;
	--button-forbidden-hover-border-color: #9E4242;

	--iframe-border-color: #E26A6A;
	--table-secondary-row-bg-color: var(--input-bg-color);
	--grouped-chapters-bg-color: var(--button-secondary-bg-color);
	--error-color: var(--button-warning-bg-color);
	--banner-cover-bg-color: var(--button-secondary-hover-bg-color);

	--syntax-highlighting-keyword: #AF00DB;
	--syntax-highlighting-bare-word: blue;
	--syntax-highlighting-string: #A31515;
	--syntax-highlighting-symbol: black;

	--monospace-font-family: monospace;
}

@media screen and (prefers-color-scheme: dark)
{
	:root
	{
		@include dark-mode();
	}
}

:root.dark
{
	@include dark-mode();
}

:root
{
	--base-font-size: 1rem;
	--size-multiplier: 1;
	--margin-large: 1rem;
	--margin-medium: 0.6rem;
	--margin-small: 0.3rem;
	--small-cover-art-width: 100px;
	--small-cover-art-height: 150px;
}

@media screen and (pointer: none), screen and (pointer: coarse), screen and (max-width: 720px)
{
	:root
	{
		--base-font-size: 1.25rem;
		--size-multiplier: 1.25;
	}
}

html,
dialog,
input,
select,
textarea,
button,
[contenteditable]
{
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	font-size: var(--base-font-size);
	background-color: var(--main-bg-color);
}

html,
input,
select,
textarea,
dialog,
[contenteditable]
{
	color: var(--main-text-color);
	caret-color: var(--main-text-color);
}

html
{
	position: relative;
	min-height: 100%;
}

body
{
	margin: 0;
}

h1
{
	font-size: 1.75rem;
}

h2
{
	font-size: 1.5rem;
}

h3
{
	font-size: 1.25rem;
}

h1,
h2,
h3
{
	margin: var(--margin-large) 0;
}

a,
a:link,
a:visited
{
	text-decoration: none;
}

a:not(.button-like),
a:not(.button-like):link,
a:not(.button-like):visited
{
	color: var(--link-text-color);
	font-weight: bold;
}

h1 a:not(.button-like),
h2 a:not(.button-like),
h3 a:not(.button-like),
h4 a:not(.button-like),
h5 a:not(.button-like),
h6 a:not(.button-like),
h1 a:not(.button-like):link,
h2 a:not(.button-like):link,
h3 a:not(.button-like):link,
h4 a:not(.button-like):link,
h5 a:not(.button-like):link,
h6 a:not(.button-like):link,
h1 a:not(.button-like):visited,
h2 a:not(.button-like):visited,
h3 a:not(.button-like):visited,
h4 a:not(.button-like):visited,
h5 a:not(.button-like):visited,
h6 a:not(.button-like):visited
{
	color: var(--heading-link-text-color);
}

a:not(.button-like):hover,
a:not(.button-like):active
{
	color: var(--active-link-text-color);
}

h1 a:not(.button-like):hover,
h2 a:not(.button-like):hover,
h3 a:not(.button-like):hover,
h4 a:not(.button-like):hover,
h5 a:not(.button-like):hover,
h6 a:not(.button-like):hover,
h1 a:not(.button-like):active,
h2 a:not(.button-like):active,
h3 a:not(.button-like):active,
h4 a:not(.button-like):active,
h5 a:not(.button-like):active,
h6 a:not(.button-like):active
{
	color: var(--heading-link-text-color);
	text-decoration: underline;
}

input:not([type=submit]),
select,
textarea,
[contenteditable]
{
	color: var(--input-text-color);
	background-color: var(--input-bg-color);
	border: 1px solid var(--input-border-color);
	outline: none;
}

input[type=submit],
button,
.button-like
{
	display: inline-block;
	box-sizing: border-box;
	color: var(--button-primary-text-color);
	background-color: var(--button-primary-bg-color);
	border: 1px solid var(--button-primary-border-color);
	box-shadow: none;
	outline: none;
	padding: calc(5px * var(--size-multiplier)) calc(10px * var(--size-multiplier));
	cursor: pointer;
	text-align: center;
	user-select: none;
	vertical-align: middle;
	white-space: nowrap;
}

input[type=submit]:disabled,
button:disabled,
.button-like[data-disabled]
{
	opacity: 0.6;
	cursor: default;
}

input[type=submit]:hover:not([disabled]),
button:hover:not([disabled]),
.button-like:hover:not([data-disabled])
{
	background-color: var(--button-primary-hover-bg-color);
	border-color: var(--button-primary-hover-border-color);
}

input[type=submit]:active:not([disabled]),
button:active:not([disabled]),
.button-like:active:not([data-disabled])
{
	background-color: var(--button-primary-active-bg-color);
	border-color: var(--button-primary-active-border-color);
}

input[type=submit].secondary,
button.secondary,
.button-like.secondary
{
	color: var(--button-secondary-text-color);
	background-color: var(--button-secondary-bg-color);
	border: 1px solid var(--button-secondary-border-color);
}

input[type=submit].secondary:hover:not([disabled]),
button.secondary:hover:not([disabled]),
.button-like.secondary:hover:not([data-disabled])
{
	background-color: var(--button-secondary-hover-bg-color);
	border-color: var(--button-secondary-hover-border-color);
}

input[type=submit].secondary:active:not([disabled]),
button.secondary:active:not([disabled]),
.button-like.secondary:active:not([data-disabled])
{
	background-color: var(--button-secondary-active-bg-color);
	border-color: var(--button-secondary-active-border-color);
}

input[type=submit].warning,
button.warning,
.button-like.warning
{
	color: var(--button-warning-text-color);
	background-color: var(--button-warning-bg-color);
	border: 1px solid var(--button-warning-border-color);
}

input[type=submit].warning:hover:not([disabled]),
button.warning:hover:not([disabled]),
.button-like.warning:hover:not([data-disabled])
{
	background-color: var(--button-warning-hover-bg-color);
	border-color: var(--button-warning-hover-border-color);
}

input[type=submit].warning:active:not([disabled]),
button.warning:active:not([disabled]),
.button-like.warning:active:not([data-disabled])
{
	background-color: var(--button-warning-active-bg-color);
	border-color: var(--button-warning-active-border-color);
}

.warning:not(input[type=submit]):not(button):not(.button-like)
{
	color: var(--error-color);
}

input[type=submit].required,
button.required,
.button-like.required
{
	color: var(--button-required-text-color);
	background-color: var(--button-required-bg-color);
	border-color: var(--button-required-border-color);
}

input[type=submit].required:hover:not([disabled]),
input[type=submit].required:active:not([disabled]),
button.required:hover:not([disabled]),
button.required:active:not([disabled]),
.button-like.required:hover:not([disabled]),
.button-like.required:active:not([disabled])
{
	color: var(--button-required-hover-text-color);
	background-color: var(--button-required-hover-bg-color);
	border-color: var(--button-required-hover-border-color);
}

button.required .three-state-icon-wrapper,
.button-like.required .three-state-icon-wrapper
{
	background-color: var(--button-required-icon-bg-color);
}

button.required:hover:not([disabled]) .three-state-icon-wrapper,
button.required:active:not([disabled]) .three-state-icon-wrapper,
.button-like.required:hover:not([disabled]) .three-state-icon-wrapper,
.button-like.required:active:not([disabled]) .three-state-icon-wrapper
{
	background-color: var(--button-required-hover-icon-bg-color);
}

input[type=submit].forbidden,
button.forbidden,
.button-like.forbidden
{
	color: var(--button-forbidden-text-color);
	background-color: var(--button-forbidden-bg-color);
	border-color: var(--button-forbidden-border-color);
}

input[type=submit].forbidden:hover:not([disabled]),
input[type=submit].forbidden:active:not([disabled]),
button.forbidden:hover:not([disabled]),
button.forbidden:active:not([disabled]),
.button-like.forbidden:hover:not([disabled]),
.button-like.forbidden:active:not([disabled])
{
	color: var(--button-forbidden-hover-text-color);
	background-color: var(--button-forbidden-hover-bg-color);
	border-color: var(--button-forbidden-hover-border-color);
}

button.forbidden .three-state-icon-wrapper,
.button-like.forbidden .three-state-icon-wrapper
{
	background-color: var(--button-forbidden-icon-bg-color);
}

button.forbidden:hover:not([disabled]) .three-state-icon-wrapper,
button.forbidden:active:not([disabled]) .three-state-icon-wrapper,
.button-like.forbidden:hover:not([disabled]) .three-state-icon-wrapper,
.button-like.forbidden:active:not([disabled]) .three-state-icon-wrapper
{
	background-color: var(--button-forbidden-hover-icon-bg-color);
}

iframe
{
	display: block;
	width: 100%;
	height: 16rem;
	border: 2px solid var(--iframe-border-color);
}

.icon
{
	width: var(--base-font-size);
	height: var(--base-font-size);
	vertical-align: middle;
}

@media screen and (prefers-color-scheme: dark)
{
	.icon
	{
		filter: invert(100%);
	}
}

.dark .icon,
button:not(.secondary) .icon,
.dark button:not(.secondary) .icon
{
	filter: invert(100%);
}

.light .icon
{
	filter: none;
}

a .icon,
a:link .icon,
a:visited .icon
{
	filter: invert(40%) sepia(72%) saturate(482%) hue-rotate(166deg) brightness(94%) contrast(92%);
}

a:hover .icon,
a:active .icon
{
	filter: invert(28%) sepia(20%) saturate(1799%) hue-rotate(166deg) brightness(90%) contrast(86%);
}

button > .icon,
.button-like > .icon
{
	display: block;
}

dl,
dt,
dd
{
	display: block;
	max-width: max-content;
	margin: 0;
}

dl
{
	margin: var(--margin-largs) 0;
}

dt
{
	margin-bottom: var(--margin-small);
}

dd
{
	margin-bottom: var(--margin-medium);
}

dt::after
{
	content: ':';
}

dialog
{
	outline: none;
	max-width: 80vw;
	max-height: 80vh;
}

dialog::backdrop,
.light dialog::backdrop
{
	opacity: 0.2;
	background-color: black;
}

@media screen and (prefers-color-scheme: dark)
{
	dialog::backdrop
	{
		background-color: white;
	}
}

.dark dialog::backdrop
{
	background-color: white;
}


table,
.display-table
{
	display: table;
	margin: var(--margin-large) 0;
	border-collapse: collapse;
}

.display-inline-table
{
	display: inline-table;
	border-collapse: collapse;
}

.display-table-row
{
	display: table-row;
}

th,
td,
.display-table-cell
{
	display: table-cell;
	vertical-align: top;
	padding: calc(var(--margin-smal) + 2px) calc(var(--margin-medium) + 2px);
}

table > thead > tr,
table > tbody > tr:nth-child(2n + 1),
.row-coloring:nth-child(2n + 1)
{
	background-color: var(--table-secondary-row-bg-color);
}

table > thead > tr input:not([type=submit]),
table > thead > tr select,
table > thead > tr textarea,
table > thead > tr [contenteditable],
table > tbody > tr:nth-child(2n + 1) input:not([type=submit]),
table > tbody > tr:nth-child(2n + 1) select,
table > tbody > tr:nth-child(2n + 1) textarea,
table > tbody > tr:nth-child(2n + 1) [contenteditable],
.row-coloring:nth-child(2n + 1) input:not([type=submit]),
.row-coloring:nth-child(2n + 1) select,
.row-coloring:nth-child(2n + 1) textarea,
.row-coloring:nth-child(2n + 1) [contenteditable]
{
	background-color: var(--main-bg-color);
}


table > thead + tbody > tr:nth-child(2n + 1)
{
	background-color: initial;
}

table > thead + tbody > tr:nth-child(2n + 1) input:not([type=submit]),
table > thead + tbody > tr:nth-child(2n + 1) select,
table > thead + tbody > tr:nth-child(2n + 1) textarea,
table > thead + tbody > tr:nth-child(2n + 1) [contenteditable]
{
	background-color: var(--input-bg-color);
}

table > thead + tbody > tr:nth-child(2n)
{
	background-color: var(--table-secondary-row-bg-color);
}

table > thead + tbody > tr:nth-child(2n) input:not([type=submit]),
table > thead + tbody > tr:nth-child(2n) select,
table > thead + tbody > tr:nth-child(2n) textarea,
table > thead + tbody > tr:nth-child(2n) [contenteditable]
{
	background-color: var(--main-bg-color);
}

p,
.p-like
{
	margin: var(--margin-large) 0;
}

input:not([type=submit]),
select,
textarea,
[contenteditable]
{
	border: none;
	padding: calc(5px * var(--size-multiplier)) calc(10px * var(--size-multiplier));
}

[contenteditable]
{
	display: inline-block;
	word-wrap: break-word;
	white-space: normal;
	min-width: 192px;
}

label
{
	cursor: pointer;
	user-select: none;
}

@media screen and (min-width: 721px)
{
	.push-in
	{
		margin-left: 4rem;
		margin-right: 4rem;
	}
}

.inline-list,
.inline-list > li
{
	display: inline;
	padding: 0;
}

.inline-list
{
	margin: 0;
	list-style-type: none;
}

.inline-list > li
{
	margin: 1em 0;
}

[contenteditable][placeholder]:empty:not(:focus-within)::before,
[contenteditable][placeholder][data-content-length="1"]:not(:focus-within)::before
{
	content: attr(placeholder);
	color:grey;
}

.no-break
{
	white-space: nowrap;
}

.dl-grid
{
	display: grid;
	grid-template-columns: max-content 1fr;
}

.dl-grid > dt,
.dl-grid > dd
{
	max-width: initial;
}

.dl-grid > dt:not(:last-of-type),
.dl-grid > dd:not(:last-of-type)
{
	margin-bottom: var(--margin-small);
}

.dl-grid > dt:last-of-type,
.dl-grid > dd:last-of-type
{
	margin-bottom: 0;
}

.dl-grid > dt
{
	text-align: right;
}

.dl-grid > dt::after
{
	padding-right: var(--margin-small);
}

code,
pre,
.syntax-highlighting .keyword,
.syntax-highlighting .bare-word,
.syntax-highlighting .string,
.syntax-highlighting .symbol
{
	font-family: var(--monospace-font-family);
}

.syntax-highlighting .keyword
{
	color: var(--syntax-highlighting-keyword);
}

.syntax-highlighting .bare-word
{
	color: var(--syntax-highlighting-bare-word);
}

.syntax-highlighting .string
{
	color: var(--syntax-highlighting-string);
}

.syntax-highlighting .symbol
{
	color: var(--syntax-highlighting-symbol);
}
